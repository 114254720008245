@mixin animated() {
  animation-duration: 300ms;
  animation-fill-mode: both;
  animation-iteration-count: 1;
}
.wrapper-login {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateZ(0);
  text-align: center;
}
.wrapper-login .icon-ike-logo {
  color: #1E88E5;
  line-height: 1.1;
  font-size: 6.5rem;
  text-align: center;
  display: block;
}
.login-form {
  width: 280px;
}
.forgot-pass {
  margin-left: 20px;
}
.form-footer {
  height: 80px;
  display: flex;
  justify-content: center;
}
.login-alert {
  color: #F44336;
  background: #ff000030;
  padding: 5px 35px 5px 15px;
}
.ui-input {
  position: relative;
  padding: 0;
  border: 0;
}
.ui-input input {
  border: 0;
  background: none;
  padding: 7px 0 2px;
  font-size: 2rem;
  outline: 0;
  width: 100%;
  tap-highlight-color: rgba(0, 0, 0, 0);
  touch-callout: none;
}
.ui-input input + label {
  position: relative;
  display: block;
  padding: 5px 0 20px;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: .0875em;
  font-weight: 500;
  text-align: left;
}
.ui-input input + label::before,
.ui-input input + label::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 1px;
}
.ui-input input + label::before {
  background: #BBB;
}
.ui-input input + label::after {
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  background-color: #6EB1FF;
  height: 2px;
}
.ui-input input + label span {
  position: relative;
  color: #BBB;
  transition: color 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ui-input input + label span::after {
  content: attr(data-text);
  position: absolute;
  overflow: hidden;
  left: 0;
  transform: scaleX(1);
  white-space: nowrap;
  color: #fff;
  background-image: linear-gradient(to right, #4A90E2 50%, rgba(255, 255, 255, 0) 0%);
  background-position: 100% 50%;
  background-size: 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  backface-visibility: hidden;
  perspective: 1000;
  transform: translateZ(0);
  transition: background-position 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ui-input input:focus + label::after,
.ui-input input.error + label::after,
.ui-input input:invalid + label::after,
.ui-input input.filled + label::after {
  transform: scaleX(1);
  transform-origin: left;
}
.ui-input input:focus + label span::after,
.ui-input input.error + label span::after,
.ui-input input:invalid + label span::after,
.ui-input input.filled + label span::after {
  background-image: linear-gradient(to right, #ffffff 50%, rgba(255, 255, 255, 0) 0%);
  background-position: 0% 50%;
}
.ui-input input.filled {
  color: #ffffff;
}
.ui-input input.filled + label::after {
  background-color: #ffffff;
}
.ui-input input.filled + label span::after {
  background-image: linear-gradient(to right, #ffffff 50%, rgba(255, 255, 255, 0) 0%);
  background-position: 0% 50%;
}
.ui-input input:focus {
  color: #6EB1FF;
}
.ui-input input:focus + label::after {
  background-color: #6EB1FF;
}
.ui-input input:focus + label span::after {
  background-image: linear-gradient(to right, #6EB1FF 50%, rgba(255, 255, 255, 0) 0%);
  background-position: 0% 50%;
}
.ui-input input.error,
.ui-input input:invalid {
  color: #E66161;
}
.ui-input input.error + label::after,
.ui-input input:invalid + label::after {
  background-color: #E66161;
}
.ui-input input.error + label span::after,
.ui-input input:invalid + label span::after {
  background-image: linear-gradient(to right, #E66161 50%, rgba(255, 255, 255, 0) 0%);
  background-position: 0% 50%;
}
a.login-btn {
  position: absolute;
  bottom: 0;
  text-decoration: none;
  border: 0;
  background-color: #6EB1FF;
  padding: 0.8rem 1.3rem;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  outline: 0;
  transition: background-color 300ms cubic-bezier(0.215, 0.61, 0.355, 1), color 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.login-btn:focus,
.login-btn:active,
.login-btn:hover {
  background-color: #FB8C00;
  color: white;
}
.__first,
.__second,
.__third,
.__fourth {
  animation-name: fadeIn;
  animation-duration: 180ms;
  animation-fill-mode: both;
  animation-iteration-count: 1;
}
.__first {
  animation-delay: 0;
}
.__second {
  animation-delay: 80ms;
}
.__third {
  animation-delay: 180ms;
}
.__fourth {
  animation-delay: 360ms;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -25%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
a.logout {
  text-decoration: none;
  cursor: pointer;
  color: #1E88E5;
}
a.logout:hover {
  text-decoration: none;
  cursor: pointer;
  color: #0058a2;
}
.load {
  width: 50%;
  height: 50%;
}
.change {
  font-size: 20px;
  color: #168EC3;
}
.changeCP {
  font-size: 20px;
  color: #168EC3;
}
.inprogress-stage {
  width: 80%;
}
.inprogress-head {
  width: 20%;
  text-align: right;
}
.stage-light {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  margin-right: 5px;
}
.stage-ontime {
  background: #8BC34A;
}
.stage-delay {
  background: #FFC107;
}
.stage-vdelay {
  background: #F44336;
}
.stage-contact {
  background: #1E88E5;
}
.stage-end {
  background: #BBB;
}
.stage-appointment {
  background: #C600FF;
}
.stage-black {
  background: #000;
}
.icon-mikelito {
  color: #FFF;
  font-size: 2.9rem;
  text-align: center;
  display: block;
  line-height: 1.1;
}
.icon-carro-taller {
  color: #FFF;
  font-size: 2.9rem;
  text-align: center;
  display: block;
  line-height: 1.1;
}
.wrapper-stage {
  width: 100%;
}
.wrapper-stage .id-expedient {
  cursor: pointer;
  text-decoration: underline;
  width: 9%;
}
.wrapper-stage .assistence-status {
  width: 22%;
}
.wrapper-stage .time-status {
  padding-left: 20px;
  font-size: 11px;
}
.wrapper-stage .date-assignment {
  width: 15%;
}
.wrapper-stage .date-status {
  padding-left: 20px;
}
.wrapper-stage .car-data {
  width: 15%;
}
.wrapper-stage .location {
  width: 25%;
}
.loadingpage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  z-index: 999999999;
}
.parpadea {
  animation-name: parpadeo;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}
@-moz-keyframes parpadeo {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.0;
  }
  100% {
    opacity: 1.0;
  }
}
@-webkit-keyframes parpadeo {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.0;
  }
  100% {
    opacity: 1.0;
  }
}
@keyframes parpadeo {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.0;
  }
  100% {
    opacity: 1.0;
  }
}
.btn-detail-actions {
  text-align: right;
}
.panel-detail {
  border: 1px solid #DDD;
  border-radius: 5px;
  display: inline-flex;
  width: 100%;
  margin-bottom: 10px;
}
.panel-detail span {
  color: #777;
}
.panel-detail em {
  color: #000;
  font-style: normal;
}
.detail-wrapper {
  width: 100%;
}
.title-head {
  text-transform: uppercase;
  color: #000;
}
.title-head:after {
  content: " ";
  background: #000;
  width: 100%;
  height: 2px;
  display: block;
  margin-bottom: 5px;
}
.wrap-50 {
  width: 49%;
  flex-direction: column;
  display: inline-flex;
}
.wrap-100 {
  width: 99%;
  flex-direction: column;
  display: inline-flex;
}
.gral-specification {
  display: inline-block;
}
.crane-assignment {
  display: inline-block;
}
.crane-assigned i,
.crane-assigned span {
  color: #BBB;
}
.crane-assigned:first-child i,
.crane-assigned:first-child span {
  color: #1E88E5;
}
.view-map {
  display: flex;
  width: 100%;
  padding: 10px 0 0 15px;
}
.view-map a.map {
  cursor: pointer;
}
.modal-dialog.map {
  width: 70%;
  height: 90%;
  display: flex;
}
.modal-content.map {
  width: 100%;
}
.modal-body.map {
  height: 92%;
}
.box-map {
  height: 100%;
}
.wrapper-car-detail {
  display: flex;
}
.contact-form {
  display: flex;
  flex-wrap: wrap;
  margin: 3px 0 10px 0;
}
.contact-form .help-block {
  font-size: 1.1rem;
  width: 100%;
  margin: 0 0 -5px;
}
.btn-validate {
  margin: 0 0 0 10px;
}
.form-control.tracing {
  padding: 3px;
  border-radius: 3px;
  border: 1px solid #DDD;
  font-size: 11px;
  height: 25px;
  width: 70%;
}
.wrap-54 {
  flex-direction: column;
  display: inline-flex;
  width: 54%;
}
.wrap-45 {
  flex-direction: column;
  display: inline-flex;
  width: 45%;
  padding-left: 10px;
}
.txt-success span,
.txt-success i {
  color: #4cae4c;
  font-weight: 600;
}
.cost-title {
  position: absolute;
  top: 0;
  right: 15px;
}
.track-log {
  min-height: 180px;
  max-height: 195px;
  overflow: scroll;
  overflow-x: hidden;
}
.track-log::-webkit-scrollbar {
  width: 0.8rem;
}
.track-log::-webkit-scrollbar-thumb {
  background: #DDD;
}
.track-log::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
}
.box-coments {
  background: #DDD;
  width: 98%;
  display: inline-flex;
  padding: 5px;
  border-radius: 3px;
  margin: 5px;
}
.box-coments .coments {
  width: 80%;
  flex-direction: row;
  display: flex;
  height: auto;
}
.box-coments .coments > select {
  height: auto;
  width: 40%;
  font-size: 11px;
}
.box-coments .coments > input {
  font-size: 11px;
}
.box-coments em {
  align-self: center;
  width: 10%;
}
.input-coment {
  height: 22px;
}
.cita {
  visibility: hidden;
}
.videowallMap {
  height: 80vh;
}
.texMapO {
  color: 'black';
  fontWeight: 'bold';
  font-size: 35px;
  font-weight: bold;
  text-shadow: -3px -3px 3px #7FFF00, 3px -3px 3px #7FFF00, -3px 3px 3px #7FFF00, 3px 3px 3px #7FFF00;
}
.texMap {
  color: 'black';
  fontWeight: 'bold';
  font-size: 35px;
  font-weight: bold;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  background-color: #2196F3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 50px;
}
.slider.round:before {
  border-radius: 50%;
}
.modal-header_sub {
  padding: 20px 16px;
  background-color: #5cb85c;
  color: white;
  text-align: center;
}
.palomita {
  font-size: 10px;
  /* Ajusta el tamaño de fuente a tu preferencia */
  color: black;
  /* Color del símbolo (✔) */
  padding: 1px 2px;
  /* Ajusta el espaciado a tu preferencia */
}
.negacion {
  font-size: 10px;
  /* Ajusta el tamaño de fuente a tu preferencia */
  color: black;
  /* Cambia el color a rojo para mostrar la negación */
  padding: 1px 2px;
  /* Ajusta el espaciado a tu preferencia */
}
.fullscreen-modal .modal-dialog {
  margin: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
@media (min-width: 768px) {
  .fullscreen-modal .modal-dialog {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .fullscreen-modal .modal-dialog {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .fullscreen-modal .modal-dialog {
    width: 1170px;
  }
}
.sub-menu li {
  font-size: 1em;
  padding: .25em 1em;
  line-height: 1em;
}
.sub-menu {
  padding: 0;
  max-height: 10em;
  /* 1.5 x 3 */
  overflow-y: auto;
}
.formfield * {
  vertical-align: middle;
}
.value-null:after {
  content: '▬';
  color: #F44336;
}
body {
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
}
html,
body {
  margin: 0px;
  height: 100%;
}
h6 {
  margin: 0;
  display: inline-block;
}
.ike-header .navbar {
  margin-bottom: 0;
}
.ike-header .icon-ike-logo {
  font-size: 5rem;
  color: #1E88E5;
}
.ike-header .logos {
  display: flex;
}
.ike-header .yaab-logo {
  padding-right: 10px;
  height: 50px;
}
.top-buffer {
  margin-top: 60px;
}
.navbar-ike {
  background: #0058a2;
  border: 0;
  color: #FFF;
  height: 50px;
}
.navbar-ike a {
  color: #FFF;
}
.navbar-ike .nav > li > a:focus {
  text-decoration: none;
  background-color: #257bc3;
}
.navbar-ike .nav > li > a:hover {
  text-decoration: none;
  background-color: #004a89;
}
.open > a.ike-dropdown {
  background-color: #004a89;
}
.wrapper-user {
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: flex-end;
  color: #1E88E5;
}
.wrapper-user .glyphicon-user {
  margin-right: 5px;
}
.logout {
  text-decoration: underline;
  padding-left: 20px;
}
.tab-content {
  background: #fbfbfb;
  border: 1px solid #eee;
  border-top: none;
  display: flex;
  padding: 10px 0;
  width: 100%;
  padding-bottom: 0;
}
.nav-tabs > li.active > a {
  background: #fbfbfb;
}
.dropdown-menu.view {
  cursor: pointer;
}
.tag-subservice {
  color: #000;
  background: #e6e6e6;
  padding: 3px 5px;
  border-radius: 4px;
}
.tag-maintenance:before {
  content: 'Arrastre de Grúa MTTO';
}
.tag-chatBot:before {
  content: 'Monitoreo';
}
.panel-default > .panel-heading {
  background-color: transparent;
  border-color: transparent;
  padding-bottom: 0;
}
.panel-default > .panel-heading:after {
  display: block;
  content: " ";
  background: #DDD;
  width: 100%;
  height: 1px;
  margin-top: 10px;
}
.panel-head {
  display: flex;
  justify-content: space-between;
}
.panel-body {
  padding: 10px 0;
}
.panel-default {
  margin-bottom: 10px;
}
.panel-inprogress {
  display: inline-flex;
  width: 100%;
  padding: 10px;
  color: #777;
  align-items: center;
}
.panel-notification {
  display: inline-flex;
  width: 100%;
  padding: 10px;
  color: #777;
  flex-direction: column;
  align-items: inherit;
}
.minus i {
  color: #777;
  cursor: pointer;
  font-size: 2rem;
}
.modal-title {
  font-weight: 600;
}
.modal-msj {
  font-size: 12px;
  color: #777;
  padding-top: 10px;
  display: block;
}
.btn-take {
  display: flex;
  justify-content: flex-end;
}
.btn-take div {
  padding-left: 5px;
}
.kind-assignment {
  display: inline-block;
  border-radius: 50%;
  color: #FFF;
  font-size: 1.5rem;
  width: 25px;
  height: 25px;
  text-align: center;
  padding: 3px;
  margin-left: 5px;
}
.geodirecta {
  background: #81D9FF;
}
.geodirecta:after {
  content: "GD";
}
.geolocation {
  background: #FB8C00;
}
.geolocation:after {
  content: "G";
}
.publishing {
  background: #3949AB;
}
.publishing:after {
  content: "P";
}
.hand-operated {
  background: #FF4D4D;
}
.hand-operated:after {
  content: "M";
}
.auction {
  background: #5cb85c;
}
.auction:after {
  content: "S";
}
.localsub {
  background: #BF047E;
}
.localsub:after {
  content: "SS";
}
.localautomatic {
  background: #008000;
}
.localautomatic:after {
  content: "AA";
}
.serviciosdisponibles {
  background: #A900FD;
}
.serviciosdisponibles:after {
  content: "SD";
}
.chatBot {
  background: #FFFF00;
}
.chatBot:after {
  content: "!";
  color: #000000;
}
.modal-subasta {
  color: #000000;
  font-size: 30px;
}
i {
  font-size: 1.8rem;
  line-height: 0;
  color: #000;
}
i:before {
  -webkit-font-smoothing: antialiased;
}
.car-detail-dragcrane,
.car-detail-supplyfuel,
.car-detail-changetire,
.wrapper-generic {
  padding-top: 10px;
}
.wrapper-stage,
.wrapper-head {
  display: inline-flex;
  align-items: center;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.flex-wrap > div {
  display: -webkit-box;
  padding: 0 20px 10px 0;
}
.address {
  padding-top: 10px;
}
.ident {
  padding-left: 10px;
}
#body-banner {
  padding: 5px 1px;
  text-align: center;
  height: 50px;
  overflow: hidden;
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  cursor: pointer;
}
.toast-orange {
  background-color: #F39C12;
}


@font-face {
    font-family: "icon";
    src: url("../fonts/icon.eot?6780270731c16cc2e6d6d9166cd85ca6?#iefix") format("embedded-opentype"),
url("../fonts/icon.woff?6780270731c16cc2e6d6d9166cd85ca6") format("woff"),
url("../fonts/icon.ttf?6780270731c16cc2e6d6d9166cd85ca6") format("truetype"),
url("../fonts/icon.svg?6780270731c16cc2e6d6d9166cd85ca6#icon") format("svg");
}

i {
    line-height: 1;
}

i:before {
    font-family: icon !important;
    font-style: normal;
    font-weight: normal !important;
    vertical-align: top;
}

.icon-add:before {
    content: "\f101";
}
.icon-calendar-clock:before {
    content: "\f102";
}
.icon-car-battery:before {
    content: "\f103";
}
.icon-car:before {
    content: "\f104";
}
.icon-carkm:before {
    content: "\f105";
}
.icon-carnokm:before {
    content: "\f106";
}
.icon-carro-taller:before {
    content: "\f107";
}
.icon-check:before {
    content: "\f108";
}
.icon-clipboard-check:before {
    content: "\f109";
}
.icon-clock-end:before {
    content: "\f10a";
}
.icon-clock:before {
    content: "\f10b";
}
.icon-close:before {
    content: "\f10c";
}
.icon-companion:before {
    content: "\f10d";
}
.icon-font-a:before {
    content: "\f10e";
}
.icon-gas-station:before {
    content: "\f10f";
}
.icon-ike-logo:before {
    content: "\f110";
}
.icon-map:before {
    content: "\f111";
}
.icon-mikelito:before {
    content: "\f112";
}
.icon-minus-circle:before {
    content: "\f113";
}
.icon-passenger:before {
    content: "\f114";
}
.icon-person-pin:before {
    content: "\f115";
}
.icon-pin-drop:before {
    content: "\f116";
}
.icon-plate:before {
    content: "\f117";
}
.icon-plus-circle:before {
    content: "\f118";
}
.icon-security:before {
    content: "\f119";
}
.icon-service:before {
    content: "\f11a";
}
.icon-towing:before {
    content: "\f11b";
}
.icon-transparent:before {
    content: "\f11c";
}
.icon-truck:before {
    content: "\f11d";
}
.icon-user:before {
    content: "\f11e";
}
.icon-wrench:before {
    content: "\f11f";
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Regular-webfont.eot');
    src: url('../fonts/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Roboto-Regular-webfont.woff') format('woff'),
         url('../fonts/Roboto-Regular-webfont.ttf') format('truetype'),
         url('../fonts/Roboto-Regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

